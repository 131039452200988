<template>
  <div>
    <input type="file" accept="video/mp4, video/x-m4v, video/*" capture @change="setvideo">
  </div>
</template>
<!-- eslint-disable -->
<script>
import botaoCamera from "./botaoCamera.vue";
import botaoGaleria from "./botaoGaleria.vue";
export default {
  name: "DashBoard",
  props: {},
  data() {
    return {
      decoded: {},
      menuOpen: false,
      resizedImg: null,
    };
  },
  components: { botaoGaleria, botaoCamera },
  methods: {
    setvideo(event){
      console.log(event)
    }
  },
  mounted() {},
};
</script>
